import React, { useState, useEffect } from 'react'
import UserService from "../../services/UserService.js";
// import jQuery from "jquery";
import axios from "axios";
import "../css/draftAgreement.css";
import Popup from "./Popup.jsx";

import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getNotification, getUserDetails } from "../../Redux/Action/action.js";
import Loader from "react-js-loader";
import FooterOthers from './FooterOthers.jsx';
import {
    formdata,
    fieldNames,
    storageName,
    requiredCheck,
    agreementType,
    api,
    apiRedirection,
    downloadDocName,
    previousTo,
    nextTo,
} from "../Agreement_Data/AdvanceClaim.js";
import { injectDataToProgressStorage } from '../Agreement_Data/Utils.js';
import LoaderPopup from './LoaderPopup.jsx';
import LoaderPopup2 from './LoaderPopup2.jsx';
import NoteForUser from './NoteForUser.jsx';

const { REACT_APP_CREATE_AGREEMENT_API, REACT_APP_REDIRECT_URI } = process.env;

const AdvanceClaim = () => {
    const state = useSelector((state) => state.stateReducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation()
    // const [checkBoxString, setCheckBoxString] = useState([]);
    // const [clause, setClause] = useState(true);
    const [createAgreement, setCreatAgreement] = useState("");
    const [contentState, setContentState] = useState(true);
    const [handleSubmit, setHandleSubmit] = useState(false);
    const [loaderAgreement, setLoaderAgreement] = useState(false);
    const [loaderAgreement2, setLoaderAgreement2] = useState(false);
    const [loaderDownload, setLoaderDownload] = useState(false);
    const [loaderCredit, setLoaderCredit] = useState(false);
    const [promt, setPromt] = useState(false)
    const [descriptionValue, setDescriptionValue] = useState(false);
    const [storedTable, setStoredTable] = useState([])
    const [proceedLoader, setProceedLoader] = useState(false)
    // const [other, setOther] = useState(false);
    // const [fileName, setFileName] = useState("");
    const [rename, setRename] = useState(false);
    const [renameString, setRenameString] = useState("Save as..");
    const [tagStore, setTagStore] = useState([]);
    const [joke, setJoke] = useState({
        setup: "",
        punchline: ""
    })
    const [divHeight, setDivHeight] = useState(0);
    const [ID, setID] = useState("")
    const [table2, setTable2] = useState([
        ...formdata[3].table
    ])
    const [figure, setFigure] = useState([])
    const [component, setComponent] = useState([])


    const [masterAdvanceClaim, setMasterAdvanceClaim] = useState([{ responseValue: "", visibility: false }])
    const [popup, setPopup] = useState(false)

    const [note, setNote] = useState(false)
    const [note2, setNote2] = useState(false)
    const [note3, setNote3] = useState(false)
    const [editTable, setEditTable] = useState(false)
    const [showTable, setShowTable] = useState(false)
    const [showAddOther, setShowAddOther] = useState(false)
    let agreement_dict;


    useEffect(() => {
        // if (UserService.isLoggedIn()) getPlanDetails()
        getDataFromStorage()
        resetButtonView()
        if (UserService.isLoggedIn()) getDataFromLibrary()
        const oldTable1 = getTableScript1()
        extractFigureComponenteNumbers(oldTable1)
        getTheRightHand()
    }, []);


    useEffect(() => {
        const handleKeyPress = (event) => {
            saveDatatoStorage();
        };
        document.addEventListener('input', handleKeyPress);
        document.addEventListener('click', handleKeyPress);

        return () => {
            document.removeEventListener('input', handleKeyPress);
            document.removeEventListener('click', handleKeyPress);
        };
    }, [tagStore, table2]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const getTheRightHand = () => {
        // localStorage.setItem(UserService.getUserId() + "TechnicalFieldOutput", JSON.stringify(text))
        const rightHand = JSON.parse(localStorage.getItem(UserService.getUserId() + "advanceClaimLeftOutput"))
        if (rightHand) {
            setCreatAgreement(rightHand)
            setHandleSubmit(true);
        }
    }

    const getTableScript1 = () => {
        // localStorage.setItem(UserService.getUserId() + storageName + "Table1", JSON.stringify(temp));
        const storedTable = JSON.parse(localStorage.getItem(UserService.getUserId() + "masterDFD" + "Table1"))
        if (storedTable) {
            if (checkTable(storedTable)) setStoredTable([...storedTable])
            else setStoredTable(false)
        }
        else return []
        return storedTable
    }
    const checkTable = (table) => {
        if (table.length && table[0].figure_no || table[0].figure_description || table[0].table[0].component_no || table[0].table[0].component_name || table[0].table[0].component_description || table[0].table[0].component_connected_component) return true
        else return false
    }

    const getDataFromStorage = () => {
        const masterStorageObject = JSON.parse(localStorage.getItem(UserService.getUserId() + storageName))
        if (masterStorageObject) {
            setRenameString(masterStorageObject.renameString)
            setTagStore(masterStorageObject.tags)
        }
        const masterAdvanceClaim1 = JSON.parse(localStorage.getItem(UserService.getUserId() + "advanceClaimLeftOutput"))
        if (masterAdvanceClaim1) {
            setMasterAdvanceClaim(masterAdvanceClaim1)
            setHandleSubmit(true);
        }
        const masterAdvanceClaim2 = JSON.parse(localStorage.getItem(UserService.getUserId() + "advanceClaimsLeftOutput"))
        if (masterAdvanceClaim2) {
            setTable2(masterAdvanceClaim2)
        }
    }
    const saveDatatoStorage = () => {
        const fileName = document.getElementById("input-file-name").value
        const masterStorageObject = {}
        localStorage.setItem(UserService.getUserId() + "advanceClaimLeftOutput", JSON.stringify(masterAdvanceClaim))
        localStorage.setItem(UserService.getUserId() + "advanceClaimsLeftOutput", JSON.stringify(table2))
        masterStorageObject.tags = tagStore;
        masterStorageObject.renameString = fileName;
        localStorage.setItem(UserService.getUserId() + storageName, JSON.stringify(masterStorageObject))
    }
    const extractFigureComponenteNumbers = (table1) => {
        // Initialize an empty array to store the figure numbers
        const figureNumbers = [];
        const componentNo = [];

        // Iterate over each object in the data array
        table1 && table1.forEach((item) => {
            // Check if the item has a figure_no property
            if (item.figure_no) {
                // If yes, push the figure_no value to the figureNumbers array
                figureNumbers.push(item.figure_no);
            }
            item.table && item.table.forEach((items) => {
                if (items.component_no) componentNo.push(items.component_no);
            })
        });

        // Return the array of figure numbers
        setFigure([...figureNumbers])
        setComponent([...componentNo])
    }

    const getDataFromLibrary = () => {
        if (location.state?.data) {
            const outputJSONfromLibrary = location.state?.data;
            // setOutputDetailsDataFromLibrary(location.state?.data)
            setRenameString(outputJSONfromLibrary.file_name);
            if (
                outputJSONfromLibrary.file_tags &&
                outputJSONfromLibrary.file_tags != null &&
                outputJSONfromLibrary.file_tags != "null"
            )
                setTagStore(outputJSONfromLibrary.file_tags.split(";"));

            fieldNames &&
                fieldNames.forEach((fieldName) => {
                    const field = document.getElementById(fieldName);
                    if (field) {
                        field.value =
                            outputJSONfromLibrary.api_input.tipsytom_input[fieldName];
                    } else {
                        return "";
                    }
                });
        }
    }
    const resetButtonView = () => {
        const floatEnd = document.querySelectorAll(".float-end")
        if (floatEnd.length != 0 && window.innerWidth <= "600") {
            floatEnd.forEach((e) => {
                e.classList.remove("float-end")
            })
        }
    }


    const getScrrenTOLoader = () => {
        const element = document.getElementById("my-agreement-spinner");
        const offset = -200; // Adjust this value to set the desired offset
        if (element) {
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset + offset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth", // Optionally, you can add smooth scrolling animation
            });
        }
    };

    const addNewClaim = (element, index) => {
        setMasterAdvanceClaim((prevTable) => {
            const updatedArray = [...prevTable];
            updatedArray.push({ responseValue: "", visibility: false })
            return updatedArray;
        })
        handleAddTable()
    }

    const getDepenedentOfClaim = () => {
        let tempArr = ["Independent Claim"]
        table2.forEach((ele, ind) => {
            if (ind !== table2.length - 1) tempArr.push(ind)
        })
        return tempArr
    }

    const finalizeClaim = async (value) => {
        setTimeout(() => {
            getScrrenTOLoader()
        }, 100)

        // let interval;
        // getJoke()
        // interval = setInterval(getJoke, 10000);
        let isFieldEmpty = false;
        // requiredCheck.forEach((ele) => {
        //     const fieldValue = document.getElementById(ele.field).value;
        //     if (!fieldValue) {
        //         dispatch(
        //             getNotification({
        //                 message: ele.Message,
        //                 type: "default",
        //             })
        //         );
        //     }
        //     if (fieldValue) isFieldEmpty = true;
        // });
        // if (isFieldEmpty && UserService.isLoggedIn()) {
        let tempArr = []
        masterAdvanceClaim.forEach((ele, ind) => {
            tempArr.push(ele.responseValue)
        })

        if (UserService.isLoggedIn()) {
            try {
                setLoaderAgreement2(true)
                const payload = {
                    keycloak_user_id: UserService.getUserId(),
                    agreement_type: agreementType,
                    file_name: renameString,
                    file_tags: tagStore.join(",").replaceAll(",", ";").replace(/;$/, ""),
                    session_id: "",

                    final_result: tempArr,
                    Input: table2,

                    // drawing_master: getTableScript1(),
                    // embodiment_table: table2,
                    // claim_no: String(++index),
                    // finalize: finalize,
                };

                setHandleSubmit(true);
                const getResponse = await axios.post(REACT_APP_CREATE_AGREEMENT_API + "/api/claims_advance_finalize", payload,
                    {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            access_token: UserService.getToken(),
                        }
                    }
                );
                if (getResponse.status === 200) {
                    // clearInterval(interval)
                    localStorage.setItem(UserService.getUserId() + "claimsOutputJSON", JSON.stringify(arrayToObject(masterAdvanceClaim)))
                    setID(getResponse.data.id)
                    getTitle()
                    if (value) navigate("/app")
                }

            } catch (err) {
                console.log('error', err);
                // clearInterval(interval)
                // if (index === 0) setHandleSubmit(false);
                setLoaderAgreement2(false)
                // console.log("Error", err);
                if (err.response && err.response.status === 401) {
                    dispatch(
                        getNotification({
                            message: "Session expired! Plesae log in again",
                            type: "default",
                        })
                    );
                    setTimeout(() => {
                        UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI + apiRedirection}` });
                    }, 3000);
                }
                else {
                    dispatch(
                        getNotification({
                            message: "We are experiencing high demand. Please try after a few minutes.",
                            type: "default",
                        })
                    );
                }
            }
            setLoaderAgreement2(false);
        }
        else if (!UserService.isLoggedIn()) {
            dispatch(
                getNotification({
                    message: "Session expired! Please log in again",
                    type: "default",
                })
            );
            setTimeout(() => {
                UserService.doLogin({
                    redirectUri: `${REACT_APP_REDIRECT_URI + apiRedirection}`,
                });
            }, 3000);
        }
        setLoaderAgreement2(false);
    };
    const getTitle = async () => {
        setTimeout(() => {
            getScrrenTOLoader()
        }, 100)

        // let interval;
        // getJoke()
        // interval = setInterval(getJoke, 10000);
        let isFieldEmpty = false;
        // requiredCheck.forEach((ele) => {
        //     const fieldValue = document.getElementById(ele.field).value;
        //     if (!fieldValue) {
        //         dispatch(
        //             getNotification({
        //                 message: ele.Message,
        //                 type: "default",
        //             })
        //         );
        //     }
        //     if (fieldValue) isFieldEmpty = true;
        // });
        // if (isFieldEmpty && UserService.isLoggedIn()) {
        const text = document.getElementById("edit").innerText;

        if (UserService.isLoggedIn()) {
            try {
                setProceedLoader(true);
                const payload = {
                    keycloak_user_id: UserService.getUserId(),
                    agreement_type: agreementType,
                    file_name: renameString,
                    file_tags: tagStore.join(",").replaceAll(",", ";").replace(/;$/, ""),
                    session_id: "",
                    claims: text
                };

                // setHandleSubmit(true);
                const getResponse = await axios.post(REACT_APP_CREATE_AGREEMENT_API + "/api/title_generation", payload,
                    {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            access_token: UserService.getToken(),
                        }
                    }
                );
                if (getResponse.status === 200) {
                    localStorage.setItem(UserService.getUserId() + "titleGenerationOutput", JSON.stringify(getResponse.data))
                    // clearInterval(interval)
                    navigate(nextTo)
                    // dispatch(
                    //     getNotification({
                    //         message: "Document Saved.",
                    //         type: "default",
                    //     })
                    // );
                    setID(getResponse.data.id)
                    // setTimeout(() => {
                    //     navigate(nextTo)
                    // }, 2000)
                }

            } catch (err) {
                console.log('error', err);
                // clearInterval(interval)
                // if (index === 0) setHandleSubmit(false);
                setProceedLoader(false);
                // console.log("Error", err);
                if (err.response && err.response.status === 401) {
                    dispatch(
                        getNotification({
                            message: "Session expired! Plesae log in again",
                            type: "default",
                        })
                    );
                    setTimeout(() => {
                        UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI + apiRedirection}` });
                    }, 3000);
                }
                else {
                    dispatch(
                        getNotification({
                            message: "We are experiencing high demand. Please try after a few minutes.",
                            type: "default",
                        })
                    );
                }
            }
            setProceedLoader(false)
        }
        else if (!UserService.isLoggedIn()) {
            dispatch(
                getNotification({
                    message: "Session expired! Please log in again",
                    type: "default",
                })
            );
            setTimeout(() => {
                UserService.doLogin({
                    redirectUri: `${REACT_APP_REDIRECT_URI + apiRedirection}`,
                });
            }, 3000);
        }
        setProceedLoader(false)
    };
    const getAllClaimResponse = (index) => {
        let tempArr = []
        console.log(masterAdvanceClaim);
        if (index == "0") return [""]
        else {
            masterAdvanceClaim.forEach((ele, ind) => {
                // if (ind !== masterAdvanceClaim.length - 1) 
                tempArr.push(ele.responseValue)
            })
            return tempArr
        }
    }
    const deleteThisClaim = (index) => {
        setMasterAdvanceClaim((prevArray) => {
            const newArray = [...prevArray];
            newArray.splice(index);
            return newArray;
        });
    }
    const arrayToObject = (array) => {
        const dict = array.reduce((acc, curr, index) => {
            acc[index] = curr.responseValue;
            return acc;
        }, {});

        return { dict, uploaded: false };
    };

    const sendData = async (element, index, finalize, manual) => {
        setTimeout(() => {
            getScrrenTOLoader()
        }, 100)

        // let interval;
        // getJoke()
        // interval = setInterval(getJoke, 10000);
        let isFieldEmpty = false;
        // requiredCheck.forEach((ele) => {
        //     const fieldValue = document.getElementById(ele.field).value;
        //     if (!fieldValue) {
        //         dispatch(
        //             getNotification({
        //                 message: ele.Message,
        //                 type: "default",
        //             })
        //         );
        //     }
        //     if (fieldValue) isFieldEmpty = true;
        // });
        // if (isFieldEmpty && UserService.isLoggedIn()) {

        const refine = () => {
            let tempArr = [...table2]
            tempArr = tempArr.filter((ele, ind) => index === ind)
            return tempArr
        }

        if (UserService.isLoggedIn()) {
            try {
                setLoaderAgreement(true);
                const payload = {
                    keycloak_user_id: UserService.getUserId(),
                    agreement_type: agreementType,
                    file_name: renameString,
                    file_tags: tagStore.join(",").replaceAll(",", ";").replace(/;$/, ""),
                    session_id: "",

                    previous_claim: getAllClaimResponse(index),
                    drawing_master: getTableScript1(),
                    embodiment_table: refine(),
                    claim_no: String(++index),
                    finalize: finalize,
                };
                setHandleSubmit(true);
                if (!manual) {
                    const getResponse = await axios.post(REACT_APP_CREATE_AGREEMENT_API + api, payload,
                        {
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                                access_token: UserService.getToken(),
                            }
                        }
                    );

                    if (getResponse.status === 200) {
                        // clearInterval(interval)
                        const text = getResponse.data.agreement_text;
                        // setCreatAgreement(text);
                        setID(getResponse.data.id)
                        // localStorage.setItem(UserService.getUserId() + "claimsOutput", JSON.stringify(text))
                        // getPlanDetails("noRefresh");
                        // console.log("Success");
                        injectDataToProgressStorage("Claims Playground")
                        const firstDiv = document.querySelector('.firstDiv');
                        firstDiv && setDivHeight(firstDiv.offsetHeight + 58);
                        index -= 1
                        let updatedArray = [...masterAdvanceClaim];
                        updatedArray[index]["responseValue"] = text;
                        updatedArray[index]["visibility"] = true;
                        agreement_dict = arrayToObject(updatedArray)

                        // agreement_dict = arrayToObject(updatedArray)
                        setMasterAdvanceClaim((prevTable) => {
                            let updatedArray = [...prevTable];
                            updatedArray[index]["responseValue"] = text;
                            updatedArray[index]["visibility"] = true;
                            return updatedArray;
                        })
                        saveDatatoStorage();
                        localStorage.setItem(UserService.getUserId() + "claimsOutputJSON", JSON.stringify(agreement_dict))
                    }
                }
                else {
                    injectDataToProgressStorage("Claims Playground")
                    const firstDiv = document.querySelector('.firstDiv');
                    firstDiv && setDivHeight(firstDiv.offsetHeight + 58);
                    index -= 1


                    let updatedArray = [...masterAdvanceClaim];
                    updatedArray[index]["responseValue"] = table2[index]["claims"];
                    updatedArray[index]["visibility"] = true;
                    agreement_dict = arrayToObject(updatedArray)


                    setMasterAdvanceClaim((prevTable) => {
                        let updatedArray = [...prevTable];
                        updatedArray[index]["responseValue"] = table2[index]["claims"];
                        updatedArray[index]["visibility"] = true;
                        return updatedArray;
                    })
                    localStorage.setItem(UserService.getUserId() + "claimsOutputJSON", JSON.stringify(agreement_dict))
                }
            } catch (err) {
                console.log('error', err);
                // clearInterval(interval)
                setHandleSubmit(false);
                setLoaderAgreement(false)
                // console.log("Error", err);
                if (err.response && err.response.status === 401) {
                    dispatch(
                        getNotification({
                            message: "Session expired! Plesae log in again",
                            type: "default",
                        })
                    );
                    setTimeout(() => {
                        UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI + apiRedirection}` });
                    }, 3000);
                }
                else {
                    dispatch(
                        getNotification({
                            message: "We are experiencing high demand. Please try after a few minutes.",
                            type: "default",
                        })
                    );
                }
            }
            setLoaderAgreement(false);
        }
        else if (!UserService.isLoggedIn()) {
            dispatch(
                getNotification({
                    message: "Session expired! Please log in again",
                    type: "default",
                })
            );
            setTimeout(() => {
                UserService.doLogin({
                    redirectUri: `${REACT_APP_REDIRECT_URI + apiRedirection}`,
                });
            }, 3000);
        }
        setLoaderAgreement(false);
    };
    const handleInputChange = (e, tableIndex, field, table, data) => {
        const { value } = e.target;
        if (value === "Add Other") {
            setTable2((prevTable) => {
                const updatedTable = [...prevTable];
                updatedTable[tableIndex].showOther = true;
                return updatedTable;
            });
        }
        setTable2((prevTable) => {
            const updatedTable = [...prevTable];
            updatedTable[tableIndex][field] = value;
            return updatedTable;
        });
    };
    const handleInputChangeForSelect = (e, tableIndex, field, table, data) => {
        const { value } = e.target;
        if (value !== "Independent Claim") {
            let newValue = Number(value)
            newValue = newValue + 1
            newValue = String(newValue)

            setTable2((prevTable) => {
                const updatedTable = [...prevTable];
                updatedTable[newValue][field] = newValue;
                return updatedTable;
            });
        }
        else {
            setTable2((prevTable) => {
                const updatedTable = [...prevTable];
                updatedTable[tableIndex][field] = value;
                return updatedTable;
            });
        }
    };
    const handleInputChangeForRows = (e, figure, component, tableIndex, rowIndex, table) => {
        if (e.target.checked) {
            setTable2((prevTable) => {
                const updatedTable = [...prevTable];
                updatedTable[tableIndex].table.push({ "figure_no": figure, "component_no": component })
                return updatedTable;
            });
        }
        else {
            setTable2((prevTable) => {
                const updatedTable = [...prevTable];
                updatedTable[tableIndex].table = updatedTable[tableIndex].table.filter(
                    (item) => !(item.figure_no === figure && item.component_no === component)
                );
                return updatedTable;
            });
        }

    };
    const handleAddRow = (tableIndex, table) => {
        setTable2((prevTable) => {
            const updatedTable = [...prevTable];
            updatedTable[tableIndex].table.push({
                figure_no: "",
                component_no: "",
            });
            return updatedTable;
        });
    };
    const handleRemoveLastRow = (tableIndex, table) => {
        setTable2((prevTable) => {
            const updatedTable = [...prevTable];
            // Remove the last row from the table
            updatedTable[tableIndex].table.pop();
            return updatedTable;
        });
    };

    const handleAddTable = () => {
        setTable2((prevTable) => [
            ...prevTable,
            {
                claims: "",
                type_of_claim: "",
                dependent_on_claim: "",
                visibility: false,
                showOther: false,
                table: [
                    {
                        figure_no: "",
                        component_no: "",
                    },
                ]
            },
        ]);
    };

    const handleRemoveLastTable = (table) => {
        setTable2((prevTable) => {
            // Remove the last element from the table2 array
            const updatedTable = [...prevTable];
            updatedTable.pop();
            return updatedTable;
        });
    };



    const download = async () => {
        if (UserService.isLoggedIn()) {
            const text = getAllClaimResponse().join(", "); // Use any separator you want            
            // setCreatAgreement(text)
            try {
                const FormData = require("form-data");
                const sendData = new FormData();
                sendData.append("keycloak_user_id", UserService.getUserId());
                sendData.append("input_text", text);
                const config = {
                    method: "post",
                    url: `${REACT_APP_CREATE_AGREEMENT_API}/api/export_as_docx`,
                    responseType: "blob",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "access_token": UserService.getToken()
                    },
                    data: sendData,
                };
                setLoaderDownload(true);
                const getResponse = await axios(config);
                if (getResponse.status === 200) {
                    const getCurrentDate = () => {
                        const currentDate = new Date();
                        const year = String(currentDate.getFullYear()).slice(-4);
                        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
                        const day = String(currentDate.getDate()).padStart(2, "0");
                        return `${year}-${day}-${month}`;
                    };
                    const blob = new Blob([getResponse.data], {
                        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
                    link.download = `${downloadDocName + UserService.getUsername()}_${getCurrentDate()}.docx`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    dispatch(
                        getNotification({
                            message: "Downloaded successfully",
                            type: "success",
                        })
                    );
                } else if (getResponse.status === 401) {
                    dispatch(
                        getNotification({
                            message: "Session expired! Plesae log in again",
                            type: "default",
                        })
                    );
                    setTimeout(() => {
                        UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI + apiRedirection}` });
                    }, 3000);
                }
            } catch (err) {
                setLoaderDownload(false);
                console.log("Error", err);
                dispatch(
                    getNotification({
                        message: "We are experiencing high demand. Please try after a few minutes.",
                        type: "default",
                    })
                );
            }
            setLoaderDownload(false);
        } else {
            dispatch(
                getNotification({
                    message: "Session expired! Plesae log in again",
                    type: "default",
                })
            );
            setTimeout(() => {
                UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI + apiRedirection}` });
            }, 3000);
        }

    };


    const resetForm = () => {
        fieldNames.forEach((fieldName) => {
            const field = document.getElementById(fieldName);
            if (field) {
                field.value = '';
            }
        });

        setRenameString("New File")
        let temp = []
        setTagStore(temp)
    };

    const getTagButtonFromArray = () => {
        const tag = document.querySelector(".custom-input")
        let tempArr = [...tagStore]
        if (tag.value === "") {
            dispatch(
                getNotification({
                    message: "Please enter a tag.",
                    type: "default",
                })
            );
        }
        else {
            tempArr.push(tag.value)
        }
        setTagStore(tempArr);
        tag.value = ""
    }

    const removeTagFromArray = (value) => {
        let tempArr = [...tagStore]
        if (value) tempArr = tempArr.filter(e => e !== value)
        setTagStore(tempArr);
    }

    const getJoke = async () => {
        try {

            const response = await axios.get("https://official-joke-api.appspot.com/random_joke")
            // console.log(response.data);
            setJoke({
                setup: response.data.setup,
                punchline: response.data.punchline
            })
        }
        catch (err) {
            console.log("error", err);
        }
    }

    const editFile = async (index) => {
        if (UserService.isLoggedIn()) {
            setMasterAdvanceClaim((prevTable) => {
                // Remove the last element from the table2 array
                const updatedArray = [...prevTable];
                updatedArray[index].responseValue = document.querySelectorAll("#edit")[index].innerText
                localStorage.setItem(UserService.getUserId() + "claimsOutputJSON", JSON.stringify(arrayToObject(updatedArray)))
                return updatedArray;
            })
        } else {
            dispatch(
                getNotification({
                    message: "Session expired! Plesae log in again",
                    type: "default",
                })
            );
            setTimeout(() => {
                UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI + apiRedirection}` });
            }, 3000);
        }
        // 
    };
    return (
        <div id="contract" className="container-fluid contract">
            <div className="contractForm mt-1">
                <div className={storedTable && showTable ? "" : "d-none"}>
                    {!editTable && <button
                        onClick={() => {
                            setEditTable(true)
                        }}
                        onMouseEnter={(e) => {
                            e.target.innerHTML = "Edit Table"
                        }}
                        onMouseLeave={(e) => {
                            e.target.innerHTML = '<i class="fas fa-edit"></i>';
                        }}
                        className='btn btn-primary absolute -right-2 top-'>
                        <i className='fas fa-edit'></i>
                    </button>}
                    {editTable && <button
                        onClick={() => {
                            setEditTable(false)
                            const updatedData = extractTableData();
                            setStoredTable(updatedData);
                            function extractTableData() {
                                const rows = document.querySelectorAll('tbody tr');
                                let data = [];
                                let currentFigure = {};

                                rows.forEach((row, rowIndex) => {
                                    const cells = row.querySelectorAll('td, th');
                                    if (rowIndex % 2 === 0) {
                                        // New figure row
                                        currentFigure = {
                                            figure_no: cells[0].innerText,
                                            figure_description: cells[1].innerText,
                                            table: []
                                        };
                                        data.push(currentFigure);
                                    }
                                    currentFigure.table.push({
                                        component_no: cells[2].innerText,
                                        component_name: cells[3].innerText,
                                        component_description: cells[4].innerText,
                                        component_connected_component: cells[5].innerText
                                    });
                                });

                                return data;
                            };
                            localStorage.setItem(UserService.getUserId() + "masterDFDTable1", JSON.stringify(updatedData));
                        }}
                        onMouseEnter={(e) => {
                            e.target.innerHTML = "Save Table"
                        }}
                        onMouseLeave={(e) => {
                            e.target.innerHTML = '<i class="fas fa-save"></i>';
                        }}
                        className='btn btn-primary absolute -right-2 top-'>
                        <i className='fas fa-save'></i>
                    </button>}
                </div>
                <div className={handleSubmit ? 'containe' : 'container'}>
                    <div className='shadow sm:overflow-hidden sm:rounded-md mb-5'>
                        <table className={storedTable.length && showTable ? "table table-bordered" : "d-none"}>
                            <thead>
                                <tr>
                                    <th scope="col">Figure Number</th>
                                    <th scope="col">Figure Description</th>
                                    <th scope="col">Component Number</th>
                                    <th scope="col">Component Name</th>
                                    <th scope="col">Component Description</th>
                                    <th scope="col">Connected to component</th>
                                </tr>
                            </thead>
                            <tbody className='text-center' contentEditable={editTable}>
                                {storedTable && storedTable.map((ele, ind) => (
                                    ele.table && ele.table.map((inerEle, innerInd) => (
                                        <tr key={innerInd}>
                                            <th scope="row">{ele.figure_no}</th>
                                            <td>{ele.figure_description}</td>
                                            <td>{inerEle.component_no}</td>
                                            <td>{inerEle.component_name}</td>
                                            <td>{inerEle.component_description}</td>
                                            <td>{inerEle.component_connected_component}</td>
                                        </tr>
                                    ))
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className='new-form'>
                        <div className="shadow sm:overflow-hidden sm:rounded-md mb-5">
                            <div className={"row p-2"}>
                                <div className={handleSubmit ? "row" : "d-none"} style={{ background: "linear-gradient(to right, #D8E3F9, white,white)" }} role="alert">
                                    <div className='col-sm'>
                                        {/* <span className="bg-white text-primary px-3 py-1 tracking-widest text-xs absolute left-0 -top-2 rounded-bl">Free</span> */}
                                        <h4 className='ml-5 text-primary w-2/3'>{agreementType}</h4>
                                    </div>
                                    <div className='col-sm items-center float-end'>
                                        <button
                                            onClick={() => {
                                                setNote3(true)
                                            }}
                                            spiketip-title="Go back to home page" spiketip-pos="bottom"
                                            className={`btn btn-outline-primary float-end mx-2 ${handleSubmit ? "mt-3" : ""}`}
                                        >
                                            &#8592; Home
                                        </button>
                                        <button
                                            // spiketip-title="Reset Form" spiketip-pos="bottom"
                                            onClick={() => {
                                                setNote2(true)
                                            }}
                                            className={`btn btn-outline-dark float-end mx-2 ${handleSubmit ? "mt-3" : ""}`}
                                        >
                                            <i className="fas fa-redo mr-2"></i>
                                            Reset Form
                                        </button>
                                        {storedTable && <button
                                            // spiketip-title="Reset Form" spiketip-pos="bottom"
                                            onClick={() => {
                                                setShowTable(!showTable)
                                            }}
                                            className={`btn btn-outline-dark float-end mx-1 ${handleSubmit ? "mt-3" : ""}`}
                                        >
                                            <i className="fas fa-table mr-2"></i>
                                            <span>
                                                {showTable ? "Hide Figure Descriptions" : "Refer Figure Descriptions"}
                                            </span>
                                        </button>}
                                    </div>
                                </div>
                                <hr className={handleSubmit ? 'mt-0 text-white' : 'mt-3'} />
                                <div className="row menu-bar mt-1 container-fluid">
                                    <div className={handleSubmit ? "" : `col-sm px-2`}>
                                        <button className={`btn btn-light ml-3 ${handleSubmit ? "mb-2 w-5/6" : "w-full"}`} >
                                            <input
                                                id="input-file-name"
                                                className={rename ? "float-start w-4/6" : "d-none"}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") setRename(false);
                                                }}
                                                onChange={(e) => {
                                                    setRenameString(e.target.value);
                                                }}
                                                defaultValue={renameString}
                                                type="text"
                                                style={{
                                                    // width: "150px",
                                                    height: "30px",
                                                    color: "black",
                                                }}
                                            />
                                            <span
                                                onClick={() => {
                                                    if (rename) setRename(false);
                                                    else setRename(true);
                                                }}
                                                id="file-name" spiketip-title={`${renameString}`} spiketip-pos={renameString.length > 40 ? "right" : "bottom"}
                                                className={rename ? "d-none" : "float-start text-primary"}>
                                                {renameString.length > 40 ? `${renameString.slice(0, 40)}...` : renameString}
                                            </span>
                                            <span className="ml-2 float-end">
                                                |
                                                <i
                                                    onClick={() => {
                                                        if (rename) setRename(false);
                                                        else setRename(true);
                                                    }}
                                                    className={
                                                        rename ? "fas fa-check mx-3" : "fas fa-edit ml-3"
                                                    }
                                                >
                                                </i></span>
                                            {/* <i className="fas fa-times"></i> */}
                                        </button>
                                    </div>
                                    <div className={handleSubmit ? "mx-4" : `col-sm`}>
                                        <input
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    getTagButtonFromArray(e.target.value)
                                                }
                                            }}
                                            placeholder="Add tag here"
                                            className={"px-1 mt-0.5 p-1 custom-input rounded-full"}
                                            style={{
                                                height: "35px",
                                                // fontSize: handleSubmit ? "14px" : "",
                                                width: "110px",
                                                border: "2px solid #007BFF",
                                                textAlign: "left",
                                            }}
                                            type="text"
                                        />
                                        <button
                                            spiketip-title={"Add Tag"} spiketip-pos="bottom"
                                            onClick={() => getTagButtonFromArray()}
                                            id="add-tag"
                                            className="btn btn-outline-primary round-btn mx-2 -mt-1"
                                        >
                                            <i className="fas fa-plus plus-position"></i>
                                        </button>
                                        <span className="scrollable-span mx-1 mt-1 pb-1 w-full sm:w-4/6">
                                            {tagStore.length > 0 &
                                                tagStore.map((ele, ind) => (
                                                    <button
                                                        style={{ borderRadius: "9999px" }}
                                                        key={ind}
                                                        className="btn btn-outline-dark p-1 px-2 px-1 position-relative"
                                                        onMouseEnter={(e) => {
                                                            const iconElement = e.target.querySelector('i');
                                                            iconElement && iconElement.classList.remove("d-none")
                                                        }}
                                                        onMouseLeave={(e) => {
                                                            const iconElement = e.target.querySelector('i');
                                                            iconElement && iconElement.classList.add("d-none")
                                                        }}
                                                    >
                                                        {ele}
                                                        <i
                                                            // style={{ display: "none" }}
                                                            onClick={() => { removeTagFromArray(ele) }}
                                                            className="fas fa-window-close ml-1 d-none"></i>
                                                    </button>
                                                ))}
                                        </span>
                                    </div>
                                    <div className="space-y-6 bg-white px-3 firstDiv">
                                        {
                                            masterAdvanceClaim && masterAdvanceClaim.map((element, index) => (
                                                <>
                                                    <h2 class="flex flex-row flex-nowrap items-center my-8">
                                                        <span class="flex-grow block border-t border-black" aria-hidden="true" role="presentation"></span>
                                                        <span class="flex-none block mx-4   px-4 py-2.5 text-xs leading-none font-medium uppercase btn-primary text-white">
                                                            Claim {index + 1}
                                                        </span>
                                                        <span class="flex-grow block border-t border-black" aria-hidden="true" role="presentation"></span>
                                                    </h2>
                                                    <div key={index} className='row' style={{ height: "500px" }}>
                                                        <div className={`h-full overflow-auto ${element.visibility ? "col-sm" : "container"}`}>
                                                            <div className="gap-1 px-2 shadow mb-5">
                                                                {formdata.map((e, i) => (
                                                                    <div key={i} className="col-span-6 sm:col-span-3">
                                                                        {
                                                                            table2.length ? e.element === "input-table-custom-drawing-select" &&
                                                                                <div className="col-span-6 sm:col-span-3 mt-1 overflow-x-auto px-2">
                                                                                    {/* {
                                                                                        e.table && table2.map((tableElement, index) => ( */}
                                                                                    <div className="col-span-6 sm:col-span-3 mt-1 overflow-x-auto px-2">
                                                                                        <label
                                                                                            // htmlFor={tableElement.id}
                                                                                            className={`block text-sm font-medium text-black}`}
                                                                                        >
                                                                                            Claim:
                                                                                            <span
                                                                                                className="text-danger mx-1">
                                                                                                *
                                                                                            </span>
                                                                                        </label>
                                                                                        {
                                                                                            (
                                                                                                <textarea
                                                                                                    value={table2[index].claims}
                                                                                                    onChange={(e) => handleInputChange(e, index, "claims", "table2")}
                                                                                                    type="text"
                                                                                                    placeholder=""
                                                                                                    row="3"
                                                                                                    id="claims"
                                                                                                    className={`mt-1 block w-full rounded-md border-indigo-1000 shadow-sm focus:border-grren focus:ring-indigo-500 sm:text-sm default-class`}
                                                                                                >
                                                                                                </textarea>
                                                                                            )
                                                                                        }
                                                                                        <label
                                                                                            // htmlFor={tableElement.id}
                                                                                            className="block text-sm font-medium text-black">
                                                                                            Type Of Claim:
                                                                                            <span
                                                                                                className={`${false ? "text-danger mx-1" : "d-none"
                                                                                                    }`}
                                                                                            >
                                                                                                *
                                                                                            </span>
                                                                                        </label>
                                                                                        {
                                                                                            <div className="relative">
                                                                                                <select
                                                                                                    value={table2[index].type_of_claim || ""}
                                                                                                    onChange={(e) => handleInputChange(e, index, "type_of_claim", "table2")}
                                                                                                    type="text"
                                                                                                    placeholder=""
                                                                                                    id="type_of_claim"
                                                                                                    className={table2[index].showOther ? "d-none" : `mt-1 block w-full rounded-md border-indigo-1000 shadow-sm focus:border-grren focus:ring-indigo-500 sm:text-sm default-class`}
                                                                                                >
                                                                                                    <option value="" disabled>--Select a type of claim--</option>
                                                                                                    <option value="Method">Method</option>
                                                                                                    <option value="Process">Process</option>
                                                                                                    <option value="System">System</option>
                                                                                                    <option value="Assembly">Assembly</option>
                                                                                                    <option value="Composition">Composition</option>
                                                                                                    <option value="Apparatus">Apparatus</option>
                                                                                                    <option value="Device">Device</option>
                                                                                                    <option value="Matter">Matter</option>
                                                                                                    <option value="Add Other">Add Other</option>
                                                                                                </select>

                                                                                                <input
                                                                                                    defaultValue={table2[index].type_of_claim}
                                                                                                    onChange={(e) => handleInputChange(e, index, "type_of_claim", "table2")}
                                                                                                    type="text"
                                                                                                    placeholder=""

                                                                                                    className={table2[index].showOther ? `mt-1 block w-full rounded-md border-indigo-1000 shadow-sm focus:border-grren focus:ring-indigo-500 sm:text-sm default-class` : "d-none"}
                                                                                                />
                                                                                                <span
                                                                                                    onClick={() => {
                                                                                                        setTable2((prevTable) => {
                                                                                                            const updatedTable = [...prevTable];
                                                                                                            updatedTable[index].showOther = false;
                                                                                                            return updatedTable;
                                                                                                        });
                                                                                                    }}
                                                                                                    title='Reset Form'
                                                                                                    // spiketip-title="Reset Field" spiketip-pos="bottom"
                                                                                                    className={table2[index].showOther ? 'absolute right-2 top-2 cursor-pointer' : "d-none"}><i className='fas fa-redo'></i></span>
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            index !== 0 &&
                                                                                            <label
                                                                                                // htmlFor={tableElement.id}
                                                                                                className="block text-sm font-medium text-black">
                                                                                                Dependent On Claim:
                                                                                                <span
                                                                                                    className={`${false ? "text-danger mx-1" : "d-none"
                                                                                                        }`}
                                                                                                >
                                                                                                    *
                                                                                                </span>
                                                                                            </label>}
                                                                                        {
                                                                                            index !== 0 &&
                                                                                            (
                                                                                                <select
                                                                                                    // value={table2[index].dependent_on_claim || ""}
                                                                                                    onChange={(e) => handleInputChangeForSelect(e, index, "dependent_on_claim", "table2", true)}
                                                                                                    className={`mt-1 block w-full rounded-md border-indigo-1000 shadow-sm focus:border-green focus:ring-indigo-500 sm:text-sm default-class`}
                                                                                                >
                                                                                                    <option value="" selected disabled>--Select a dependent on claim--</option>
                                                                                                    {getDepenedentOfClaim().map((ele, ind) => (
                                                                                                        <option className={ind === getDepenedentOfClaim().length ? "d-none" : ""} key={ind} value={ele}>{ele === "Independent Claim" ? ele : ele + 1}</option>
                                                                                                    ))}
                                                                                                </select>
                                                                                            )
                                                                                        }
                                                                                        <label
                                                                                            // htmlFor={tableElement.id}
                                                                                            className="block text-sm font-medium text-black">
                                                                                            Add Reference to Figure?
                                                                                            <span
                                                                                                className={`${false ? "text-danger mx-1" : "d-none"
                                                                                                    }`}
                                                                                            >
                                                                                                *
                                                                                            </span>
                                                                                        </label>
                                                                                        <span>
                                                                                            <input onClick={() => {
                                                                                                setTable2((prevTable) => {
                                                                                                    const updatedTable = [...prevTable];
                                                                                                    updatedTable[index].visibility = true;
                                                                                                    return updatedTable;
                                                                                                });
                                                                                            }} type="radio" id={"decider1" + index} name={"fav_language" + index} value="HTML" checked={table2[index].visibility === true} />
                                                                                            <label className="mx-2 mb-2" htmlFor={"decider1" + index}>Yes</label>
                                                                                            <input onClick={() => {
                                                                                                setTable2((prevTable) => {
                                                                                                    const updatedTable = [...prevTable];
                                                                                                    updatedTable[index].visibility = false;
                                                                                                    return updatedTable;
                                                                                                });
                                                                                            }} type="radio" id={"decider2" + index} name={"fav_language" + index} value="HTML" checked={table2[index].visibility === false} />
                                                                                            <label className="mx-2 mb-2" htmlFor={"decider2" + index}>No</label>
                                                                                        </span>
                                                                                        <table className={table2[index].visibility ? "table table-striped" : "d-none"}>
                                                                                            {/* <thead>
                                                                                                <tr>
                                                                                                    <th scope="col">Figure Nos.</th>
                                                                                                    <th scope="col">Component Nos.</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {
                                                                                                    table2[index].table.map((obj, indkey) => (
                                                                                                        <tr key={indkey}>
                                                                                                            <th>
                                                                                                                <select
                                                                                                                    value={obj.figure_no || ""}
                                                                                                                    onChange={(e) => {
                                                                                                                        handleInputChangeForRows(e, index, indkey, "figure_no", "table2")
                                                                                                                    }} name="" id="">
                                                                                                                    <option value="" disabled>--Select a Figure No--</option>
                                                                                                                    <>
                                                                                                                        {
                                                                                                                            figure.length && figure.map((item) => (
                                                                                                                                <option value={item}>{item}</option>
                                                                                                                            ))
                                                                                                                        }
                                                                                                                    </>
                                                                                                                </select>
                                                                                                            </th>
                                                                                                            <th>
                                                                                                                <select
                                                                                                                    value={obj.component_no || ""}
                                                                                                                    onChange={(e) => {
                                                                                                                        handleInputChangeForRows(e, index, indkey, "component_no", "table2")
                                                                                                                    }} name="" id="">
                                                                                                                    <option value="" disabled>--Select a Component No--</option>
                                                                                                                    <>
                                                                                                                        {
                                                                                                                            component.length && component.map((item) => (
                                                                                                                                <option value={item}>{item}</option>
                                                                                                                            ))
                                                                                                                        }
                                                                                                                    </>
                                                                                                                </select>
                                                                                                            </th>
                                                                                                        </tr>
                                                                                                    ))
                                                                                                }
                                                                                            </tbody>*/}
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th scope="col">Select</th>
                                                                                                    <th scope="col">Figure Number</th>
                                                                                                    <th scope="col">Figure Description</th>
                                                                                                    <th scope="col">Component Number</th>
                                                                                                    <th scope="col">Component Name</th>
                                                                                                    <th scope="col">Component Description</th>
                                                                                                    <th scope="col">Connected to component</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody className='text-center' contentEditable={editTable}>
                                                                                                {storedTable && storedTable.map((ele, ind) => (
                                                                                                    ele.table && ele.table.map((inerEle, innerInd) => (
                                                                                                        <tr key={innerInd}>
                                                                                                            {/* {console.log(table2[ind].table[innerInd] && table2[ind].table[innerInd].figure_no)} */}
                                                                                                            {/* {console.log("line 1",table2[ind].table[innerInd] && table2[ind].table[innerInd].figure_no,table2[ind].table[innerInd].component_no)}
                                                                                                        {console.log("line 2",ele.figure_no, ele.table[innerInd].component_no)} */}
                                                                                                            {/* {console.log(table2[ind].table[innerInd] && table2[ind].table[innerInd].figure_no === ele.figure_no && table2[ind].table[innerInd].component_no === ele.table[innerInd].component_no ? "true" : "false")} */}
                                                                                                            <th scope="row">
                                                                                                                <input type="checkbox"
                                                                                                                    // defaultChecked={table2[ind].table[innerInd] && table2[ind].table[innerInd].figure_no === ele.figure_no && table2[ind].table[innerInd].component_no === ele.table[innerInd].component_no ? true : false}
                                                                                                                    onChange={(e) => {
                                                                                                                        handleInputChangeForRows(e, ele.figure_no, inerEle.component_no, index, innerInd, "table2")
                                                                                                                    }}
                                                                                                                />
                                                                                                            </th>
                                                                                                            <th scope="row">{ele.figure_no}</th>
                                                                                                            <td>{ele.figure_description}</td>
                                                                                                            <td>{inerEle.component_no}</td>
                                                                                                            <td>{inerEle.component_name}</td>
                                                                                                            <td>{inerEle.component_description}</td>
                                                                                                            <td>{inerEle.component_connected_component}</td>
                                                                                                        </tr>
                                                                                                    ))
                                                                                                ))}
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                    {/* ))
                                                                                    } */}
                                                                                    <div className="text-center space-x-3">
                                                                                        {/* <button className="btn btn-primary"
                                                                         onClick={() => {
                                                                             handleAddTable("table2")
                                                                         }}
                                                                     >Add Embodiment
                                                                     </button> */}
                                                                                        {/* {table2.length > 1 && <button className="btn btn-outline-danger"
                                                                                            onClick={() => {
                                                                                                handleRemoveLastTable("table2")
                                                                                            }}
                                                                                        > Remove Last Embodiment
                                                                                        </button>} */}
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                e.element === "input-table-custom-drawing-select"
                                                                                &&
                                                                                <div className="text-center ms-auto text-danger alert-secondary">Press Reset Form to get the Information for each Preferred Embodiment</div>
                                                                        }
                                                                    </div>
                                                                ))
                                                                }
                                                                <div className="col-span-6 sm:col-span-3 text-center mx-2 px-4 pb-3 mb-1 mt-2">
                                                                    {/* <Link
                                                    to="/app"
                                                    spiketip-title="Go back to home page" spiketip-pos="top"
                                                    type="submit"
                                                    className="btn btn-outline-dark mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                    onClick={() => { navigate("/app") }}
                                                >
                                                    Back
                                                </Link> */}
                                                                    <button
                                                                        spiketip-title="Submit Form" spiketip-pos="top"
                                                                        type="submit"
                                                                        className="btn btn-primary mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm mx-2 px-4"
                                                                        onClick={() => { sendData(element, index, false,) }}
                                                                    >
                                                                        Submit for Generation
                                                                    </button>
                                                                    <button
                                                                        spiketip-title="Submit Form" spiketip-pos="top"
                                                                        type="submit"
                                                                        className="btn btn-primary mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm mx-2 px-4"
                                                                        onClick={() => { sendData(element, index, false, "manual") }}
                                                                    >
                                                                        Submit Manually
                                                                    </button>
                                                                    {
                                                                        index === masterAdvanceClaim.length - 1 &&
                                                                        <button
                                                                            spiketip-title="Submit Form" spiketip-pos="top"
                                                                            type="submit"
                                                                            className="btn btn-primary mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm mx-2 px-4"
                                                                            onClick={() => { addNewClaim(element, index) }}
                                                                        >
                                                                            Add Another Claim
                                                                        </button>
                                                                    }
                                                                </div>
                                                                <br />
                                                                <br />
                                                                <br />
                                                            </div>
                                                        </div>
                                                        <div className={`h-full overflow-auto ${element.visibility ? "col-sm" : "d-none"}`}>
                                                            <div
                                                                className={element.visibility ? "md:mt-0" : "d-none"}
                                                            >
                                                                <div className="sm:px-1 shadow sm:overflow-hidden sm:rounded-md py-1 px-1">
                                                                    <div className="menu-bar">
                                                                        <div className="row p-2" style={{ background: "linear-gradient(to right, #D8E3F9, white,white)" }} role="alert">
                                                                            <div className='col-sm'>
                                                                                <h5 className='ml-2 text-primary'>Claim {index + 1}</h5>
                                                                            </div>
                                                                            <div className='col-sm text-end'>
                                                                                <button className={contentState ? "d-none" : "btn btn-primary p-0 px-2 m-0"}>You are in edit mode.</button>
                                                                                <button
                                                                                    spiketip-title={contentState ? "Edit Content" : "Save Conetent"} spiketip-pos="bottom"
                                                                                    className={UserService.isLoggedIn() ? "btn btn-primary mx-4" : "btn btn-secondary mx-4"}
                                                                                    onClick={() => {
                                                                                        // console.log(document.querySelectorAll(".download"));
                                                                                        // document.querySelectorAll("#download")[index].contentEditable = true
                                                                                        if (UserService.isLoggedIn()) {
                                                                                            setContentState(!contentState);
                                                                                            if (contentState) {
                                                                                                document.querySelectorAll("#edit")[index].contentEditable = true;
                                                                                                // document.querySelectorAll("#download")[index].setAttribute("disabled", true);
                                                                                            } else {
                                                                                                document.querySelectorAll("#edit")[index].contentEditable = false;
                                                                                                // document.querySelectorAll("#download")[index].removeAttribute("disabled")
                                                                                                editFile(index)
                                                                                            }
                                                                                        }
                                                                                        else UserService.doLogin({ redirectUri: REACT_APP_REDIRECT_URI + apiRedirection })
                                                                                        // else dispatch(
                                                                                        //   getNotification({
                                                                                        //     message:
                                                                                        //       "Plesae sign up or log in to edit the document.",
                                                                                        //     type: "info",
                                                                                        //   })
                                                                                        // );
                                                                                    }}
                                                                                >
                                                                                    <i
                                                                                        id="icon"
                                                                                        className={
                                                                                            contentState ? "fas fa-edit" : "fas fa-save"
                                                                                        }
                                                                                    ></i>
                                                                                </button>
                                                                                {/* {
                                                                                    index===masterAdvanceClaim.length-1 &&
                                                                                    <button
                                                                                    spiketip-title="Download Agreement" spiketip-pos="left"
                                                                                    id="download"
                                                                                    className={UserService.isLoggedIn() ? "btn btn-primary" : "btn btn-secondary"}
                                                                                    onClick={() => {
                                                                                        if (UserService.isLoggedIn()) download();
                                                                                        else UserService.doLogin({ redirectUri: REACT_APP_REDIRECT_URI + apiRedirection })
                                                                                        // else dispatch(
                                                                                        //   getNotification({
                                                                                        //     message:
                                                                                        //       "Plesae sign up or log in to download the document.",
                                                                                        //     type: "info",
                                                                                        //   })
                                                                                        // );
                                                                                    }}
                                                                                >
                                                                                    <i className="fas fa-download"></i>
                                                                                </button>} */}
                                                                                {
                                                                                    index !== masterAdvanceClaim.length - 1 &&
                                                                                    <button
                                                                                        spiketip-title="Delete This Claim" spiketip-pos="left"
                                                                                        id="download"
                                                                                        className={UserService.isLoggedIn() ? "btn btn-danger" : "btn btn-secondary"}
                                                                                        onClick={() => {
                                                                                            setPopup(true)
                                                                                        }}
                                                                                    >
                                                                                        <i className="fas fa-trash"></i>
                                                                                    </button>}
                                                                                {popup && <Popup onConfirm={() => {
                                                                                    deleteThisClaim(index)
                                                                                    setPopup(false)
                                                                                }} onCancel={() => setPopup(false)} text={"Are you sure you want to delete this claim? Deleting it will also remove all items below it. This action cannot be undone."} />}
                                                                            </div>
                                                                        </div>
                                                                        <hr className="mt-0" />
                                                                    </div>
                                                                    <div className="px-3">
                                                                        <div
                                                                            id="edit"
                                                                            contentEditable={false}
                                                                            // style={{
                                                                            //     // height: `320px`,
                                                                            //     height: `${divHeight}px`,
                                                                            //     overflow: "auto",
                                                                            // }}
                                                                            className="mt-1 text-lg text-gray-600 whitespace-pre-wrap px-1"
                                                                        >
                                                                            {element.responseValue}
                                                                            <br />
                                                                        </div>
                                                                        <div className='text-center space-x-3 pb-3'>
                                                                            {/* {
                                                                                index === masterAdvanceClaim.length - 1 &&
                                                                                <button
                                                                                    spiketip-title="Submit Form" spiketip-pos="top"
                                                                                    type="submit"
                                                                                    className="btn btn-primary mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm mx-2 px-4"
                                                                                    onClick={() => {
                                                                                        window.location.reload()
                                                                                        localStorage.removeItem(UserService.getUserId() + storageName)
                                                                                        localStorage.removeItem(UserService.getUserId() + "advanceClaimLeftOutput")
                                                                                        handleSubmit(false)
                                                                                    }}
                                                                                >
                                                                                    Reset Form
                                                                                </button>} */}
                                                                            {/* <button
                                                     // spiketip-title="Submit Form" spiketip-pos="top"
                                                     type="submit"
                                                     className="btn btn-outline-primary mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                     onClick={() => { navigate(previousTo) }}
                                                 >
                                                     Previous
                                                 </button> */}
                                                                            {/* <button
                                                                                // spiketip-title="Submit Form" spiketip-pos="top"
                                                                                type="submit"
                                                                                className="btn btn-outline-primary mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                                onClick={() => { navigate(nextTo) }}
                                                                            >
                                                                                Next Script
                                                                            </button> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div >
                                                </>
                                            ))
                                        }
                                        <div className=''>
                                            <button
                                                // spiketip-title="" spiketip-pos="top"
                                                type="submit"
                                                className="btn btn-outline-primary float-end mt-2 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm mx-2 px-4"
                                                onClick={() => setNote(true)}
                                            >
                                                {proceedLoader ?
                                                    <div className="spinner-border mx-12 my-0" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                    :
                                                    <span>Next Script </span>
                                                }
                                            </button>
                                            {
                                                masterAdvanceClaim.length > 0 &&
                                                <button
                                                    // spiketip-title="" spiketip-pos="top"
                                                    type="submit"
                                                    className="btn btn-outline-primary float-end mt-2 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm mx-2 px-4"
                                                    onClick={() => setNote(true)}
                                                >
                                                    {proceedLoader ?
                                                        <div className="spinner-border mx-12 my-0" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                        :
                                                        <span>Save and Proceed <i className='fas fa-save'></i></span>
                                                    }
                                                </button>}
                                            {
                                                masterAdvanceClaim[0].responseValue &&
                                                <button
                                                    spiketip-title="Download Agreement" spiketip-pos="top"
                                                    className={`btn btn-${UserService.isLoggedIn() ? "primary" : "secondary"} mt-2 float-end rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`}
                                                    onClick={() => {
                                                        if (UserService.isLoggedIn()) download();
                                                        else UserService.doLogin({ redirectUri: REACT_APP_REDIRECT_URI + apiRedirection })
                                                        // else dispatch(
                                                        //   getNotification({
                                                        //     message:
                                                        //       "Plesae sign up or log in to download the document.",
                                                        //     type: "info",
                                                        //   })
                                                        // );
                                                    }}
                                                >
                                                    Download All Claims{" "}
                                                    <i className="fas fa-download mx-1"></i>
                                                </button>}
                                        </div>
                                    </div >
                                </div>
                            </div>
                        </div>
                        {loaderAgreement && <LoaderPopup />}
                        {loaderAgreement2 && <LoaderPopup2 />}
                        {note2 && <NoteForUser detail={true} note={"This will clear all fields in this Form. Are you sure you want to proceed?"} onConfirm={() => {
                            resetForm()
                            localStorage.removeItem(UserService.getUserId() + storageName)
                            localStorage.removeItem(UserService.getUserId() + "advanceClaimLeftOutput")
                            setTable2([
                                ...formdata[3].table
                            ])
                            setMasterAdvanceClaim([{ responseValue: "", visibility: false }])
                            setNote2(false)
                        }} onCancle={() => setNote2(false)} />}
                        {note && <NoteForUser detail={true} note={"This will take forward these claims and ignore any claims generated using the Simple Claims Generator. Are you sure you want to proceed?"} onConfirm={() => {
                            setNote(false)
                            finalizeClaim()
                        }} onCancle={() => setNote(false)} />}
                        {note3 && <NoteForUser detail={true} note={"Would you like to save your changes?"} onConfirm={() => {
                            setNote3(false)
                            finalizeClaim(true)

                        }} onCancle={() => setNote3(false)} />}
                        <FooterOthers />
                    </div>
                </div>
            </div>
        </div >
    );
}

export default AdvanceClaim
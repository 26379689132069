import React from 'react'
import { useNavigate } from 'react-router'
import { useEffect } from 'react'
const PushToApp = () => {
    const navigate = useNavigate()
    useEffect(() => {
        navigate("/app")
    }, [])
    return (
        <></>
    )
}

export default PushToApp
import React, { useState, useEffect } from 'react'
import UserService from "../../services/UserService.js";
// import jQuery from "jquery";
import axios from "axios";
import "../css/draftAgreement.css";
import Popup from "./Popup.jsx";

import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getNotification, getUserDetails } from "../../Redux/Action/action.js";
import Loader from "react-js-loader";
import FooterOthers from './FooterOthers.jsx';
import {
    formdata,
    fieldNames,
    storageName,
    requiredCheck,
    agreementType,
    api,
    apiRedirection,
    downloadDocName,
    previousTo,
    nextTo,
} from "../Agreement_Data/Claims.js";
import { injectDataToProgressStorage } from '../Agreement_Data/Utils.js';
import NoteForUser from './NoteForUser.jsx';


const { REACT_APP_CREATE_AGREEMENT_API, REACT_APP_REDIRECT_URI } = process.env;

const Claims = () => {
    const state = useSelector((state) => state.stateReducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation()
    // const [checkBoxString, setCheckBoxString] = useState([]);
    // const [clause, setClause] = useState(true);
    const [createAgreement, setCreatAgreement] = useState("");
    const [contentState, setContentState] = useState(true);
    const [handleSubmit, setHandleSubmit] = useState(false);
    const [loaderAgreement, setLoaderAgreement] = useState(false);
    const [loaderDownload, setLoaderDownload] = useState(false);
    const [loaderCredit, setLoaderCredit] = useState(false);
    const [promt, setPromt] = useState(false)
    const [descriptionValue, setDescriptionValue] = useState(false);
    const [storedTable, setStoredTable] = useState([])

    // const [other, setOther] = useState(false);
    // const [fileName, setFileName] = useState("");
    const [rename, setRename] = useState(false);
    const [renameString, setRenameString] = useState("Save as..");
    const [tagStore, setTagStore] = useState([]);
    const [joke, setJoke] = useState({
        setup: "",
        punchline: ""
    })
    const [divHeight, setDivHeight] = useState(0);
    const [ID, setID] = useState("")
    const [note, setNote] = useState(false)
    const [note2, setNote2] = useState(false)
    const [editTable, setEditTable] = useState(false)
    const [showTable, setShowTable] = useState(false)
    const [proceedLoader, setProceedLoader] = useState(false)


    useEffect(() => {
        // if (UserService.isLoggedIn()) getPlanDetails()
        getDataFromStorage()
        resetButtonView()
        if (UserService.isLoggedIn()) getDataFromLibrary()
        getTableScript1()
        getTheRightHand()
    }, []);


    useEffect(() => {
        const handleKeyPress = (event) => {
            saveDatatoStorage();
        };
        document.addEventListener('input', handleKeyPress);
        document.addEventListener('click', handleKeyPress);

        return () => {
            document.removeEventListener('input', handleKeyPress);
            document.removeEventListener('click', handleKeyPress);
        };
    }, [tagStore]);

    useEffect(() => {
        window.scrollTo(0, 0);
        const firstDiv = document.querySelector('.firstDiv');
        firstDiv && setDivHeight(firstDiv.offsetHeight + 58);
    }, [])

    const checkTable = (table) => {
        if (table.length && (table[0].figure_no || table[0].figure_description || table[0].table.length > 0 && (table[0].table[0].component_no || table[0].table[0].component_name || table[0].table[0].component_description || table[0].table[0].component_connected_component))) return true
        else return false
    }

    const getTheRightHand = () => {
        // localStorage.setItem(UserService.getUserId() + "TechnicalFieldOutput", JSON.stringify(text))
        const rightHand = JSON.parse(localStorage.getItem(UserService.getUserId() + "claimsRightOutput"))
        if (rightHand) {
            setCreatAgreement(rightHand)
            setHandleSubmit(true);
        }
        const newID = JSON.parse(localStorage.getItem(UserService.getUserId() + "claimsID"))
        if (newID) setID(newID)
    }

    const getTableScript1 = () => {
        // localStorage.setItem(UserService.getUserId() + storageName + "Table1", JSON.stringify(temp));
        const storedTable = JSON.parse(localStorage.getItem(UserService.getUserId() + "masterDFD" + "Table1"))
        if (storedTable) {
            if (checkTable(storedTable)) setStoredTable([...storedTable])
            else setStoredTable(false)
        }
    }

    const getDataFromStorage = () => {
        const masterStorageObject = JSON.parse(localStorage.getItem(UserService.getUserId() + storageName))
        if (masterStorageObject) {
            setRenameString(masterStorageObject.renameString)
            setTagStore(masterStorageObject.tags)
            fieldNames.forEach((fieldName) => {
                const field = document.getElementById(fieldName);
                if (field) {
                    field.value = masterStorageObject[fieldName] || '';
                }
            });
        }
    }
    const saveDatatoStorage = () => {
        const fileName = document.getElementById("input-file-name").value

        const masterStorageObject = {}

        fieldNames && fieldNames.forEach((fieldName) => {
            const field = document.getElementById(fieldName);
            const value = field ? field.value : "";
            masterStorageObject[fieldName] = value;
        });

        masterStorageObject.tags = tagStore;
        masterStorageObject.renameString = fileName;

        localStorage.setItem(UserService.getUserId() + storageName, JSON.stringify(masterStorageObject))
    }


    const getDataFromLibrary = () => {
        if (location.state?.data) {
            const outputJSONfromLibrary = location.state?.data;
            // setOutputDetailsDataFromLibrary(location.state?.data)
            setRenameString(outputJSONfromLibrary.file_name);
            if (
                outputJSONfromLibrary.file_tags &&
                outputJSONfromLibrary.file_tags != null &&
                outputJSONfromLibrary.file_tags != "null"
            )
                setTagStore(outputJSONfromLibrary.file_tags.split(";"));

            fieldNames &&
                fieldNames.forEach((fieldName) => {
                    const field = document.getElementById(fieldName);
                    if (field) {
                        field.value =
                            outputJSONfromLibrary.api_input.tipsytom_input[fieldName];
                    } else {
                        return "";
                    }
                });
        }
    }
    const resetButtonView = () => {
        const floatEnd = document.querySelectorAll(".float-end")
        if (floatEnd.length != 0 && window.innerWidth <= "600") {
            floatEnd.forEach((e) => {
                e.classList.remove("float-end")
            })
        }
    }


    const getScrrenTOLoader = () => {
        const element = document.getElementById("my-agreement-spinner");
        const offset = -200; // Adjust this value to set the desired offset
        if (element) {
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset + offset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth", // Optionally, you can add smooth scrolling animation
            });
        }
    };

    const sendData = async (value) => {
        setTimeout(() => {
            getScrrenTOLoader()
        }, 100)

        let interval;
        // getJoke()
        // interval = setInterval(getJoke, 10000);
        let isFieldEmpty = false;
        requiredCheck.forEach((ele) => {
            const fieldValue = document.getElementById(ele.field).value;
            if (!fieldValue) {
                dispatch(
                    getNotification({
                        message: ele.Message,
                        type: "default",
                    })
                );
            }
            if (fieldValue) isFieldEmpty = true;
        });
        if (isFieldEmpty && UserService.isLoggedIn()) {
            try {
                setLoaderAgreement(true);
                const FormData = require("form-data");
                const sendData = new FormData();
                sendData.append("keycloak_user_id", UserService.getUserId());
                sendData.append("agreement_type", agreementType);
                sendData.append("file_name", renameString);
                sendData.append("file_tags", tagStore.join(",").replaceAll(",", ";").replace(/;$/, ''));
                sendData.append("session_id", "");
                // sendData.append("application_no", "123");
                // sendData.append("application_tags", "abcd");


                fieldNames.forEach((fieldName) => {
                    const field = document.getElementById(fieldName);
                    const value = field ? field.value : "";
                    sendData.append(fieldName, value);
                });

                const config = {
                    method: "post",
                    url: `${REACT_APP_CREATE_AGREEMENT_API + api}`,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "access_token": UserService.getToken()
                    },
                    data: sendData,
                };
                setHandleSubmit(true);
                const getResponse = await axios(config);
                if (getResponse.status === 200) {
                    clearInterval(interval)
                    const text = getResponse.data.agreement_text;
                    const agreement_dict = { dict: getResponse.data.agreement_dict, uploaded: false }
                    setCreatAgreement(text);
                    setID(getResponse.data.id)
                    localStorage.setItem(UserService.getUserId() + "claimsID", JSON.stringify(getResponse.data.id))
                    localStorage.setItem(UserService.getUserId() + "claimsRightOutput", JSON.stringify(text))
                    localStorage.setItem(UserService.getUserId() + "claimsOutputJSON", JSON.stringify(agreement_dict))
                    // getPlanDetails("noRefresh");
                    // console.log("Success");
                    injectDataToProgressStorage("Claims Playground")
                    const firstDiv = document.querySelector('.firstDiv');
                    firstDiv && setDivHeight(firstDiv.offsetHeight + 58);
                }

            } catch (err) {
                console.log('error', err);
                clearInterval(interval)
                setHandleSubmit(false);
                setLoaderAgreement(false)
                // console.log("Error", err);
                if (err.response && err.response.status === 401) {
                    dispatch(
                        getNotification({
                            message: "Session expired! Plesae log in again",
                            type: "default",
                        })
                    );
                    setTimeout(() => {
                        UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI + apiRedirection}` });
                    }, 3000);
                }
                else {
                    dispatch(
                        getNotification({
                            message: "We are experiencing high demand. Please try after a few minutes.",
                            type: "default",
                        })
                    );
                }
            }
            setLoaderAgreement(false);
        }
        else if (!UserService.isLoggedIn()) {
            dispatch(
                getNotification({
                    message: "Session expired! Please log in again",
                    type: "default",
                })
            );
            setTimeout(() => {
                UserService.doLogin({
                    redirectUri: `${REACT_APP_REDIRECT_URI + apiRedirection}`,
                });
            }, 3000);
        }
        setLoaderAgreement(false);

    };

    const download = async () => {
        if (UserService.isLoggedIn()) {
            const text = document.getElementById("edit").innerText;
            setCreatAgreement(text)
            try {
                const FormData = require("form-data");
                const sendData = new FormData();
                sendData.append("keycloak_user_id", UserService.getUserId());
                sendData.append("input_text", text);
                const config = {
                    method: "post",
                    url: `${REACT_APP_CREATE_AGREEMENT_API}/api/export_as_docx`,
                    responseType: "blob",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "access_token": UserService.getToken()
                    },
                    data: sendData,
                };
                setLoaderDownload(true);
                const getResponse = await axios(config);
                if (getResponse.status === 200) {
                    const getCurrentDate = () => {
                        const currentDate = new Date();
                        const year = String(currentDate.getFullYear()).slice(-4);
                        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
                        const day = String(currentDate.getDate()).padStart(2, "0");
                        return `${year}-${day}-${month}`;
                    };
                    const blob = new Blob([getResponse.data], {
                        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
                    link.download = `${downloadDocName + UserService.getUsername()}_${getCurrentDate()}.docx`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    dispatch(
                        getNotification({
                            message: "Downloaded successfully",
                            type: "success",
                        })
                    );
                } else if (getResponse.status === 401) {
                    dispatch(
                        getNotification({
                            message: "Session expired! Plesae log in again",
                            type: "default",
                        })
                    );
                    setTimeout(() => {
                        UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI + apiRedirection}` });
                    }, 3000);
                }
            } catch (err) {
                setLoaderDownload(false);
                console.log("Error", err);
                dispatch(
                    getNotification({
                        message: "We are experiencing high demand. Please try after a few minutes.",
                        type: "default",
                    })
                );
            }
            setLoaderDownload(false);
        } else {
            dispatch(
                getNotification({
                    message: "Session expired! Plesae log in again",
                    type: "default",
                })
            );
            setTimeout(() => {
                UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI + apiRedirection}` });
            }, 3000);
        }

    };


    const resetForm = () => {
        fieldNames.forEach((fieldName) => {
            const field = document.getElementById(fieldName);
            if (field) {
                field.value = '';
            }
        });
        setRenameString("New File")
        setHandleSubmit(false)
        let temp = []
        setTagStore(temp)
    };

    const getTagButtonFromArray = () => {
        const tag = document.querySelector(".custom-input")
        let tempArr = [...tagStore]
        if (tag.value === "") {
            dispatch(
                getNotification({
                    message: "Please enter a tag.",
                    type: "default",
                })
            );
        }
        else {
            tempArr.push(tag.value)
        }
        setTagStore(tempArr);
        tag.value = ""
    }

    const removeTagFromArray = (value) => {
        let tempArr = [...tagStore]
        if (value) tempArr = tempArr.filter(e => e !== value)
        setTagStore(tempArr);
    }

    const getJoke = async () => {
        try {

            const response = await axios.get("https://official-joke-api.appspot.com/random_joke")
            // console.log(response.data);
            setJoke({
                setup: response.data.setup,
                punchline: response.data.punchline
            })
        }
        catch (err) {
            console.log("error", err);
        }
    }
    const getObject = (text) => {
        const claims = {};
        // Split the text by double newline characters
        const lines = text.split('\n\n');

        lines.forEach((line, index) => {
            line = line.trim();
            if (line && !line.toLowerCase().startsWith("i/we claim")) {
                claims[index] = line;
            }
        });
        return { dict: claims, uploaded: false };
    }
    const editFile = async () => {
        if (UserService.isLoggedIn()) {
            const text = document.getElementById("edit").innerText;
            try {
                const FormData = require("form-data");
                const sendData = new FormData();
                sendData.append("keycloak_user_id", UserService.getUserId());
                sendData.append("id", String(ID));
                sendData.append("edited_text", text);
                const config = {
                    method: "post",
                    url: `${REACT_APP_CREATE_AGREEMENT_API}/api/edit_file`,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "access_token": UserService.getToken()
                    },
                    data: sendData,
                };
                // setLoaderDownload(true);
                const getResponse = await axios(config);
                if (getResponse.status === 200) {
                    localStorage.setItem(UserService.getUserId() + "claimsOutput", JSON.stringify(text))
                    // dispatch(
                    //     getNotification({
                    //         message: "The file has been successfully edited.",
                    //         type: "success",
                    //     })
                    // );
                    setTimeout(() => {
                        const text = document.getElementById("edit").innerText;
                        localStorage.setItem(UserService.getUserId() + "claimsRightOutput", JSON.stringify(text))
                        localStorage.setItem(UserService.getUserId() + "claimsOutputJSON", JSON.stringify(getObject(text)))
                    }, 200)
                } else if (getResponse.status === 401) {
                    dispatch(
                        getNotification({
                            message: "Session expired! Plesae log in again",
                            type: "default",
                        })
                    );
                    setTimeout(() => {
                        UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI + apiRedirection}` });
                    }, 3000);
                }
            } catch (err) {
                // setLoaderDownload(false);
                console.log("Error", err);
                dispatch(
                    getNotification({
                        message: "We are experiencing high demand. Please try after a few minutes.",
                        type: "default",
                    })
                );
            }
            // setLoaderDownload(false);
        } else {
            dispatch(
                getNotification({
                    message: "Session expired! Plesae log in again",
                    type: "default",
                })
            );
            setTimeout(() => {
                UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI + apiRedirection}` });
            }, 3000);
        }
        // 
    };

    const getTitle = async () => {
        setTimeout(() => {
            getScrrenTOLoader()
        }, 100)

        // let interval;
        // getJoke()
        // interval = setInterval(getJoke, 10000);
        let isFieldEmpty = false;
        // requiredCheck.forEach((ele) => {
        //     const fieldValue = document.getElementById(ele.field).value;
        //     if (!fieldValue) {
        //         dispatch(
        //             getNotification({
        //                 message: ele.Message,
        //                 type: "default",
        //             })
        //         );
        //     }
        //     if (fieldValue) isFieldEmpty = true;
        // });
        // if (isFieldEmpty && UserService.isLoggedIn()) {
        const text = document.getElementById("edit").innerText;

        if (UserService.isLoggedIn()) {
            try {
                setProceedLoader(true);
                const payload = {
                    keycloak_user_id: UserService.getUserId(),
                    agreement_type: agreementType,
                    file_name: renameString,
                    file_tags: tagStore.join(",").replaceAll(",", ";").replace(/;$/, ""),
                    session_id: "",
                    claims: text
                };

                // setHandleSubmit(true);
                const getResponse = await axios.post(REACT_APP_CREATE_AGREEMENT_API + "/api/title_generation", payload,
                    {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            access_token: UserService.getToken(),
                        }
                    }
                );
                if (getResponse.status === 200) {
                    localStorage.setItem(UserService.getUserId() + "titleGenerationOutput", JSON.stringify(getResponse.data))
                    // clearInterval(interval)
                    navigate(nextTo)
                    // dispatch(
                    //     getNotification({
                    //         message: "Document Saved.",
                    //         type: "default",
                    //     })
                    // );
                    setID(getResponse.data.id)
                    // setTimeout(() => {
                    //     navigate(nextTo)
                    // }, 2000)
                }

            } catch (err) {
                console.log('error', err);
                // clearInterval(interval)
                // if (index === 0) setHandleSubmit(false);
                setProceedLoader(false);
                // console.log("Error", err);
                if (err.response && err.response.status === 401) {
                    dispatch(
                        getNotification({
                            message: "Session expired! Plesae log in again",
                            type: "default",
                        })
                    );
                    setTimeout(() => {
                        UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI + apiRedirection}` });
                    }, 3000);
                }
                else {
                    dispatch(
                        getNotification({
                            message: "We are experiencing high demand. Please try after a few minutes.",
                            type: "default",
                        })
                    );
                }
            }
            setProceedLoader(false)
        }
        else if (!UserService.isLoggedIn()) {
            dispatch(
                getNotification({
                    message: "Session expired! Please log in again",
                    type: "default",
                })
            );
            setTimeout(() => {
                UserService.doLogin({
                    redirectUri: `${REACT_APP_REDIRECT_URI + apiRedirection}`,
                });
            }, 3000);
        }
        setProceedLoader(false)
    };









    return (
        <div id="contract" className="container-fluid contract">
            {loaderCredit ? (
                <div style={{ marginTop: "30vh" }} className="md:col-span-2 md:mt-0">
                    <Loader
                        type="bubble-spin"
                        bgColor={"#000000"}
                        title={"Loading..."}
                        color={"#000000"}
                        size={100}
                    />
                </div>
            ) : (
                <div className="contractForm mt-1">
                    <div className={storedTable && showTable ? "" : "d-none"}>
                        {!editTable && <button
                            onClick={() => {
                                setEditTable(true)
                            }}
                            onMouseEnter={(e) => {
                                e.target.innerHTML = "Edit Table"
                            }}
                            onMouseLeave={(e) => {
                                e.target.innerHTML = '<i class="fas fa-edit"></i>';
                            }}
                            className='btn btn-primary absolute -right-2 top-'>
                            <i className='fas fa-edit'></i>
                        </button>}
                        {editTable && <button
                            onClick={() => {
                                setEditTable(false)
                                const updatedData = extractTableData();
                                setStoredTable(updatedData);
                                function extractTableData() {
                                    const rows = document.querySelectorAll('tbody tr');
                                    let data = [];
                                    let currentFigure = {};

                                    rows.forEach((row, rowIndex) => {
                                        const cells = row.querySelectorAll('td, th');
                                        if (rowIndex % 2 === 0) {
                                            // New figure row
                                            currentFigure = {
                                                figure_no: cells[0].innerText,
                                                figure_description: cells[1].innerText,
                                                table: []
                                            };
                                            data.push(currentFigure);
                                        }
                                        currentFigure.table.push({
                                            component_no: cells[2].innerText,
                                            component_name: cells[3].innerText,
                                            component_description: cells[4].innerText,
                                            component_connected_component: cells[5].innerText
                                        });
                                    });

                                    return data;
                                };
                                localStorage.setItem(UserService.getUserId() + "masterDFDTable1", JSON.stringify(updatedData));
                            }}
                            onMouseEnter={(e) => {
                                e.target.innerHTML = "Save Table"
                            }}
                            onMouseLeave={(e) => {
                                e.target.innerHTML = '<i class="fas fa-save"></i>';
                            }}
                            className='btn btn-primary absolute -right-2 top-'>
                            <i className='fas fa-save'></i>
                        </button>}
                    </div>
                    <div className={handleSubmit ? '' : 'container'}>
                        <div className='shadow sm:rounded-md max-h-96 overflow-y-auto'>
                            <table className={storedTable.length && showTable ? "table table-bordered" : "d-none"}>
                                <thead>
                                    <tr>
                                        <th className="text-center align-middle" scope="col">Figure Number</th>
                                        <th className='text-center align-middle' scope="col">Figure Description</th>
                                        <th className='text-center align-middle' scope="col">Component Number</th>
                                        <th className='text-center align-middle' scope="col">Component Name</th>
                                        <th className="text-center align-middle" scope="col">Component Description</th>
                                        <th className='text-center align-middle' scope="col">Connected to component</th>
                                    </tr>
                                </thead>
                                <tbody className='text-center' contentEditable={editTable}>
                                    {storedTable && storedTable.map((ele, ind) => (
                                        ele.table && ele.table.map((inerEle, innerInd) => (
                                            <tr key={innerInd}>
                                                <th scope="row">{ele.figure_no}</th>
                                                <td>{ele.figure_description}</td>
                                                <td>{inerEle.component_no}</td>
                                                <td>{inerEle.component_name}</td>
                                                <td>{inerEle.component_description}</td>
                                                <td>{inerEle.component_connected_component}</td>
                                            </tr>
                                        ))
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div>
                        <div className="xl:grid xl:grid-cols-4 xl:gap-6" style={{ marginBottom: "30px" }}>
                            <div
                                style={{
                                    // height: "1353px",
                                }}
                                className={
                                    handleSubmit ? "md:col-span-2" : "md:col-span-4 container"
                                }
                            >
                                <div className="shadow sm:overflow-hidden sm:rounded-md mb-5">
                                    <div className={handleSubmit ? "d-none" : "row p-2"} style={{ background: "linear-gradient(to right, #D8E3F9, white,white)" }} role="alert">
                                        <div className='col-sm'>
                                            {/* <span className="bg-white text-primary px-3 py-1 tracking-widest text-xs absolute left-0 -top-2 rounded-bl">Free</span> */}
                                            <h4 className='ml-5 text-primary'>{agreementType}</h4>
                                        </div>
                                        <div className='col-sm items-center float-end'>
                                            <Link
                                                to="/app"
                                                spiketip-title="Go back to home page" spiketip-pos="bottom"

                                                className={`btn btn-outline-primary float-end mx-2 ${handleSubmit ? "mt-3" : ""}`}
                                            >
                                                &#8592; Home
                                            </Link>
                                            <button
                                                // spiketip-title="Reset Form" spiketip-pos="bottom"
                                                onClick={() => {
                                                    setNote(true)
                                                }}
                                                className={`btn btn-outline-dark float-end mx-2 ${handleSubmit ? "mt-3" : ""}`}
                                            >
                                                <i className="fas fa-redo mr-2"></i>
                                                Reset Form
                                            </button>
                                            {storedTable && <button
                                                // spiketip-title="Reset Form" spiketip-pos="bottom"
                                                onClick={() => {
                                                    setShowTable(!showTable)
                                                }}
                                                className={`btn btn-outline-dark float-end mx-1 ${handleSubmit ? "mt-3" : ""}`}
                                            >
                                                <i className="fas fa-table mr-2"></i>
                                                <span>
                                                    {showTable ? "Hide Figure Descriptions" : "Refer Figure Descriptions"}
                                                </span>
                                            </button>}
                                        </div>
                                    </div>
                                    <hr className={handleSubmit ? 'mt-0 text-white' : 'mt-0'} />
                                    <div className="row menu-bar mt-1 container-fluid">
                                        <div className={handleSubmit ? "" : `col-sm px-2`}>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <button className={`btn btn-light ml-3 w-full ${handleSubmit ? "mb-2" : ""}`} >
                                                        <input
                                                            id="input-file-name"
                                                            className={rename ? "float-start w-4/6" : "d-none"}
                                                            onKeyDown={(e) => {
                                                                if (e.key === "Enter") setRename(false);
                                                            }}
                                                            onChange={(e) => {
                                                                setRenameString(e.target.value);
                                                            }}
                                                            defaultValue={renameString}
                                                            type="text"
                                                            style={{
                                                                // width: "150px",
                                                                height: "30px",
                                                                color: "black",
                                                            }}
                                                        />
                                                        <span
                                                            onClick={() => {
                                                                if (rename) setRename(false);
                                                                else setRename(true);
                                                            }}
                                                            id="file-name" spiketip-title={`${renameString}`} spiketip-pos={renameString.length > 40 ? "right" : "bottom"}
                                                            className={rename ? "d-none" : "float-start text-primary"}>
                                                            {renameString.length > 40 ? `${renameString.slice(0, 40)}...` : renameString}
                                                        </span>
                                                        <span className="ml-2 float-end">
                                                            |
                                                            <i
                                                                onClick={() => {
                                                                    if (rename) setRename(false);
                                                                    else setRename(true);
                                                                }}
                                                                className={
                                                                    rename ? "fas fa-check mx-3" : "fas fa-edit ml-3"
                                                                }
                                                            >
                                                            </i></span>

                                                        {/* <i className="fas fa-times"></i> */}
                                                    </button>
                                                </div>
                                                <div className="col-sm-6">
                                                    <button
                                                        // spiketip-title="Reset Form" spiketip-pos="bottom"
                                                        onClick={() => {
                                                            setNote(true)
                                                        }}
                                                        className={`btn btn-outline-dark float-end ${handleSubmit ? "" : "d-none"}`}
                                                    >
                                                        <i className="fas fa-redo mr-2"></i>
                                                        Reset Form
                                                    </button>
                                                    {storedTable && <button
                                                        // spiketip-title="Reset Form" spiketip-pos="bottom"
                                                        onClick={() => {
                                                            setShowTable(!showTable)
                                                        }}
                                                        className={`btn btn-outline-dark float-end mx-1 ${handleSubmit ? "" : "d-none"}`}
                                                    >
                                                        <i className="fas fa-table mr-2"></i>
                                                        <span className='' style={{ fontSize: "12px" }}>
                                                            {showTable ? "Hide Figure Descriptions" : "Refer Figure Descriptions"}
                                                        </span>
                                                    </button>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={handleSubmit ? "mx-4" : `col-sm`}>
                                            <input
                                                onKeyDown={(e) => {
                                                    if (e.keyCode === 13) {
                                                        getTagButtonFromArray(e.target.value)
                                                    }
                                                }}
                                                placeholder="Add tag here"
                                                className={"px-1 mt-0.5 p-1 custom-input rounded-full"}
                                                style={{
                                                    height: "35px",
                                                    // fontSize: handleSubmit ? "14px" : "",
                                                    width: "110px",
                                                    border: "2px solid #007BFF",
                                                    textAlign: "left",
                                                }}
                                                type="text"
                                            />
                                            <button
                                                spiketip-title={"Add Tag"} spiketip-pos="bottom"
                                                onClick={() => getTagButtonFromArray()}
                                                id="add-tag"
                                                className="btn btn-outline-primary round-btn mx-2 -mt-1"
                                            >
                                                <i className="fas fa-plus plus-position"></i>
                                            </button>

                                            <span className="scrollable-span mx-1 mt-1 pb-1 w-full sm:w-4/6">
                                                {tagStore.length > 0 &&

                                                    tagStore.map((ele, ind) => (
                                                        <button
                                                            style={{ borderRadius: "9999px" }}
                                                            key={ind}
                                                            className="btn btn-outline-dark p-1 px-2 px-1 position-relative"
                                                            onMouseEnter={(e) => {
                                                                const iconElement = e.target.querySelector('i');
                                                                iconElement && iconElement.classList.remove("d-none")
                                                            }}
                                                            onMouseLeave={(e) => {
                                                                const iconElement = e.target.querySelector('i');
                                                                iconElement && iconElement.classList.add("d-none")
                                                            }}
                                                        >
                                                            {ele}
                                                            <i
                                                                // style={{ display: "none" }}
                                                                onClick={() => { removeTagFromArray(ele) }}
                                                                className="fas fa-window-close ml-1 d-none"></i>
                                                        </button>
                                                    ))}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="space-y-6 bg-white px-3 firstDiv">
                                        <div className="grid grid-cols-3 gap-1 px-2 mt-4">
                                            {formdata.map((e, i) => (
                                                <div key={i} className="col-span-6 sm:col-span-3">
                                                    <label
                                                        htmlFor={e.id}
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        {e.name}
                                                        <span
                                                            className={`${e.required ? "text-danger" : "d-none"
                                                                }`}
                                                        >
                                                            *
                                                        </span>
                                                    </label>
                                                    {e.type === "text" && <input
                                                        type={e.type ? e.type : "text"}
                                                        placeholder={e.placeholder}
                                                        name={e.id}
                                                        id={e.id}

                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                    />}
                                                    {e.type === "textarea" &&
                                                        <textarea
                                                            rows={e.row || 12}
                                                            type={e.type ? e.type : "text"}
                                                            placeholder={e.placeholder}
                                                            name={e.id}
                                                            id={e.id}

                                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                        >
                                                        </textarea>}
                                                </div>
                                            ))}

                                        </div>
                                    </div>
                                    <div className="col-span-6 sm:col-span-3 text-center mx-2 px-4 pb-3 mb-1 mt-2">
                                        <Link
                                            to="/app"
                                            spiketip-title="Go back to home page" spiketip-pos="top"
                                            type="submit"
                                            className="btn btn-outline-dark mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            onClick={() => { navigate("/app") }}
                                        >
                                            Back
                                        </Link>
                                        <button
                                            spiketip-title="Submit Form" spiketip-pos="top"
                                            type="submit"
                                            className="btn btn-primary mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm mx-2 px-4"
                                            onClick={() => { sendData() }}
                                        >
                                            Submit
                                        </button>
                                        <button
                                            // spiketip-title="Submit Form" spiketip-pos="top"
                                            type="submit"
                                            className="btn btn-outline-primary mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm mr-2"
                                            onClick={() => { navigate("/app/advance-claim") }}
                                        >
                                            Switch to Advanced
                                        </button>
                                    </div>
                                </div>
                                {/* </form> */}
                            </div>
                            {loaderAgreement ? (
                                <div
                                    id="my-agreement-spinner"
                                    style={{ marginTop: "10vh" }}
                                    className="md:col-span-2 md:mt-0"
                                >
                                    <Loader
                                        type="bubble-ping"
                                        bgColor={"#000000"}
                                        title="Please wait while the result is being generated."
                                        color={"#000000"}
                                        size={100}
                                    />
                                    <br />
                                    <br />
                                    {/* <h5 className={document.getElementById("toggle-button").classList.contains("active") ? "d-none" : "font-italic text-center mt-5"}> The strongest of all warriors are these two - time and patience</h5>
                                    <br />
                                    <figcaption className={document.getElementById("toggle-button").classList.contains("active") ? "d-none" : "blockquote-footer mb-0 font-italic text-end"}>
                                        Mark Twain
                                    </figcaption> */}
                                    {/* <h5 className="font-italic text-center mt-2"> {joke.punchline}</h5> */}
                                </div>
                            ) : loaderDownload ? (
                                <div
                                    style={{ marginTop: "30vh" }}
                                    className="md:col-span-2 md:mt-0"
                                >
                                    <Loader
                                        type="box-rotate-z"
                                        bgColor={"#000000"}
                                        title={"Downloading.."}
                                        color={"#000000"}
                                        size={50}
                                    />
                                </div>
                            ) : (
                                <div
                                    className={handleSubmit ? "md:col-span-2 md:mt-0" : "d-none"}
                                >
                                    <div className="sm:px-1 shadow sm:overflow-hidden sm:rounded-md py-1 px-1">
                                        <div className="menu-bar">
                                            <div className="row p-2" style={{ background: "linear-gradient(to right, #D8E3F9, white,white)" }} role="alert">
                                                <div className='col-sm'>
                                                    <h5 className='ml-2 text-primary'>{agreementType}</h5>
                                                </div>
                                                <div className='col-sm text-end'>
                                                    <button className={contentState ? "d-none" : "btn btn-primary p-0 px-2 m-0"}>You are in edit mode.</button>
                                                    <button
                                                        spiketip-title={contentState ? "Edit Manually" : "Save"} spiketip-pos="bottom"
                                                        className={UserService.isLoggedIn() ? "btn btn-primary mx-4" : "btn btn-secondary mx-4"}
                                                        onClick={() => {
                                                            if (UserService.isLoggedIn()) {
                                                                setContentState(!contentState);
                                                                if (contentState) {
                                                                    document.getElementById(
                                                                        "edit"
                                                                    ).contentEditable = true;
                                                                    document
                                                                        .getElementById("download")
                                                                        .setAttribute("disabled", true);
                                                                } else {
                                                                    document.getElementById(
                                                                        "edit"
                                                                    ).contentEditable = false;
                                                                    document
                                                                        .getElementById("download")
                                                                        .removeAttribute("disabled");

                                                                    editFile(dispatch, REACT_APP_CREATE_AGREEMENT_API, REACT_APP_REDIRECT_URI, apiRedirection, ID)
                                                                }
                                                            }
                                                            else UserService.doLogin({ redirectUri: REACT_APP_REDIRECT_URI + apiRedirection })
                                                            // else dispatch(
                                                            //   getNotification({
                                                            //     message:
                                                            //       "Plesae sign up or log in to edit the document.",
                                                            //     type: "info",
                                                            //   })
                                                            // );
                                                        }}
                                                    >
                                                        <i
                                                            id="icon"
                                                            className={
                                                                contentState ? "fas fa-edit" : "fas fa-save"
                                                            }
                                                        ></i>
                                                    </button>
                                                    <button
                                                        spiketip-title="Download this part in docx format" spiketip-pos="left"
                                                        id="download"
                                                        className={UserService.isLoggedIn() ? "btn btn-primary" : "btn btn-secondary"}
                                                        onClick={() => {
                                                            if (UserService.isLoggedIn()) download();
                                                            else UserService.doLogin({ redirectUri: REACT_APP_REDIRECT_URI + apiRedirection })
                                                            // else dispatch(
                                                            //   getNotification({
                                                            //     message:
                                                            //       "Plesae sign up or log in to download the document.",
                                                            //     type: "info",
                                                            //   })
                                                            // );
                                                        }}
                                                    >
                                                        <i className="fas fa-download"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <hr className="mt-0" />
                                        </div>
                                        <div className="px-3">
                                            <div
                                                id="edit"
                                                contentEditable={false}
                                                style={{
                                                    // height: `320px`,
                                                    height: `${divHeight}px`,
                                                    overflow: "auto",
                                                }}
                                                className="mt-1 text-lg text-gray-600 whitespace-pre-wrap px-1"
                                            >
                                                {createAgreement}
                                                <br />
                                            </div>
                                            <div className='text-center space-x-3 pb-3'>
                                                {/* <button
                                                    spiketip-title="Download Agreement" spiketip-pos="top"
                                                    className={`btn btn-${UserService.isLoggedIn() ? "primary" : "secondary"} mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`}
                                                    onClick={() => {
                                                        if (UserService.isLoggedIn()) download();
                                                        else UserService.doLogin({ redirectUri: REACT_APP_REDIRECT_URI + apiRedirection })
                                                        // else dispatch(
                                                        //   getNotification({
                                                        //     message:
                                                        //       "Plesae sign up or log in to download the document.",
                                                        //     type: "info",
                                                        //   })
                                                        // );
                                                    }}
                                                >
                                                    Download{" "}
                                                    <i className="fas fa-download mx-1"></i>
                                                </button> */}
                                                {/* <button
                                                    // spiketip-title="Submit Form" spiketip-pos="top"
                                                    type="submit"
                                                    className="btn btn-outline-primary mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                    onClick={() => { navigate(previousTo) }}
                                                    >
                                                    Previous
                                                </button> */}
                                                <button
                                                    // spiketip-title="Submit Form" spiketip-pos="top"
                                                    type="submit"
                                                    className="btn btn-outline-primary mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                    onClick={() => {
                                                        setNote2(true)
                                                    }}
                                                >
                                                    {proceedLoader ?
                                                        <div className="spinner-border mx-12 my-0" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                        :
                                                        <span>Save and Proceed <i className='fas fa-save'></i></span>

                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        {note && <NoteForUser detail={true} note={"This will clear all fields in this Form. Are you sure you want to proceed?"} onConfirm={() => {
                            setNote(false)
                            resetForm()
                        }} onCancle={() => setNote(false)} />}
                        {note2 && <NoteForUser detail={true} note={"This will take forward these claims instead of any you have generated using the Advanced Claims Generator. Are you sure you want to proceed?"} onConfirm={() => {
                            setNote2(false)
                            if (UserService.isLoggedIn()) {
                                const text = document.getElementById("edit").innerText;
                                localStorage.setItem(UserService.getUserId() + "claimsOutputJSON", JSON.stringify(getObject(text)))
                                editFile()
                                getTitle()
                            }
                            else UserService.doLogin({ redirectUri: REACT_APP_REDIRECT_URI + apiRedirection })
                        }} onCancle={() => setNote2(false)} />}
                        <FooterOthers />
                    </div>
                </div>
            )
            }
        </div >
    );
}

export default Claims